<!--- V-SHOW WORSE PERFORMANCE ON INITIAL-LOAD, BETTER PERFORMANCE ON TOGGLING !--->
<!--- V-IF BETTER PERFORMANCE ON INITIAL-LOAD, WORSE PERFORMANCE ON TOGGLING !--->

<template>
    <h1 v-if="organizer.name" v-text="'Bearbeitung ' + organizer.name"></h1>
    <OrganizerMenu />
    <!--- SHOW THE PLUS BUTTON IN GREEN AND CREATE NEW PARTICIPANT ON CLICK !--->
    <i id="plus" class="green fas fa-plus" @click="append()"></i>
    
    <!--- LOOP OVER ALL PARTICIPANTS !--->
    <div class="participant" v-for="participant in organizer.participants">
        <input class="data-one-row" type="text" v-model="participant.name" :readonly="isEditable !== participant.id" required placeholder="Name" maxlength="30">

        <a v-bind:href="'https://sceddl.ch/teilnehmer/' + participant.slug"  target="_blank" v-if="isEditable !== participant.id" class="data-one-row">{{ 'https://sceddl.ch/teilnehmer/' + participant.slug }}</a>

        <label v-if="isEditable !== participant.id" class="data-one-row" @click="copySlug(participant.slug)">Link kopieren</label>

        <!--- ICONS FOR EDIT OR DELETE !--->
        <div v-if="isEditable !== participant.id" class="clicks">
            <i class="fas fa-edit" @click="edit(participant)" title="Bearbeiten"></i><br><br>
            <i class="fas fa-trash" @click="remove(participant)" title="Löschen"></i>
        </div>
        <div v-if="isEditable === participant.id" class="clicks">
            <i class="fas fa-check-square" @click="save(participant)" title="Speichern"></i><br><br>
            <i class="fas fa-ban" @click="cancel(participant)" title="Abbrechen"></i>
        </div>
    </div>

    <transition name="fade">
        <label v-if="success" class="data-one-row fade success" v-text="notificationText"></label>
    </transition>

    <transition name="fade">
        <label v-if="error" class="data-one-row fade error" v-text="notificationText"></label>
    </transition>
</template>

<script>
    import OrganizerMenu from "../components/OrganizerMenu.vue";

    export default {
        components: {
            OrganizerMenu
        },

        data() {
            return {
                organizer: JSON,
                slug_private: this.$route.params.slug_private,

                /*** DEFAULT EDITABLE-ID ***/
                isEditable: 0,

                /*** VALUE IF EVENT WAS EDITED OR NEW CREATED FOR SAVE AND ALSO FOR REGISTERING ALL PARTICIPANTS FOR AN EVENT ON NEW EVENT ***/
                edited: false,
                
                /*** NOTIFICATION VALUES ***/
                success: false,
                error: false,
                notificationText: ""
            };
        },

        methods: {
            async getParticipants() {
                try {
                    const response = await axios.get(`/api/${this.slug_private}/bearbeiten/participants`);
                        if (response.status === 200 && response.data.organizer) {
                        this.organizer = response.data.organizer;
                    }
                } catch (error) {
                    console.error(error);
                }
            },

            copySlug(slug) {
                const LinkToCopy = 'https://sceddl.ch/teilnehmer/' + slug;
                if (navigator.clipboard) {
                navigator.clipboard.writeText(LinkToCopy)
                    .then(() => {
                        this.success = true;
                        this.notificationText = "Link kopiert!"
                        /*** FADE OUT AND TURN VALUE INTO INITIAL VALUE AFTER 5SEK. ***/
                        setTimeout(() => {
                            this.success = false;
                            this.notificationText = ""
                        }, 5000);
                    })
                    .catch(() => {
                        alert('Fehler beim Kopieren!');
                    });
                } else {
                    alert('Fehler beim Kopieren! Sie verwenden vermutlich einen zu alten Browser');
                }
            },
            
            /*** APPEND EVENT OR PARTICIPANT ***/
            append() {
                /*** "IF 0" NEEDED TO PREVENT EDITING MULTIPLE EVENTS OR PARTICIPANTS ***/
                if (this.isEditable === 0) {
                    this.isEditable = undefined;
                    /*** APPEND A PARTICIPANT-ACCORDION ***/
                    this.
                    organizer.participants.unshift(this.create());
                }
            },
            
            create() {
                return {
                    organizer_id: this.organizer.id,
                    slug: '',
                    name: ''
                }
            },

            edit(data) {
                if (this.isEditable === 0) {
                    /*** SAVE TEMPORARY PREVIOUS VALUES ***/
                    this._tmpInputCache = Object.assign({}, data);
                    /*** SET EVENT-ID AND MAKE IT EDITABLE ***/
                    this.isEditable = data.id;
                    this.edited = true;
                }
            },

            cancel(data) {
                /*** CANCEL EXISTING PARTICIPANT ***/
                if (this.isEditable !== undefined) {
                    /*** RETURN PREVIOUS VALUES ***/
                    Object.assign(data, this._tmpInputCache);
                    this.edited = false;
                /*** CANCEL NEW PARTICIPANT ***/
                } else {
                    this.organizer.participants.splice(this.organizer.participants.indexOf(data), 1);
                }
                this.isEditable = 0;
            },

            async save(participant) {
                if (this.edited === false) {
                    /*** CREATE NEW PARTICIPANT ***/
                    try {
                        await axios.post('/api/teilnehmer/neu', participant).then(response => {
                            participant.id = response.data.id;
                            participant.slug = response.data.slug;       
                            this.isEditable = 0;
                            this.organizer.participants.sort((a,b) => a.name.toUpperCase().localeCompare(b.name.toUpperCase()));
                            this.success = true;
                            this.notificationText = "Gespeichert!"
                            /*** FADE OUT AND TURN VALUE INTO INITIAL VALUE AFTER 5SEK. ***/
                            setTimeout(() => {
                                this.success = false;
                                this.notificationText = ""
                            }, 5000);
                    });
                    } catch(error) {
                        this.error = true;
                        alert('Es gab einen Fehler beim Speichern der Daten. Bitte überprüfe deine Internetverbindung und versuche es erneut.');
                    }
                } else {
                    /*** UPDATE PARTICIPANT ***/
                    try {
                        await axios.put('/api/teilnehmer/bearbeiten', participant)
                        this.edited = false;
                        this.isEditable = 0;
                        this.organizer.participants.sort((a,b) => a.name.toUpperCase().localeCompare(b.name.toUpperCase()));
                        this.success = true;
                        this.notificationText = "Gespeichert!"
                        /*** FADE OUT AND TURN VALUE INTO INITIAL VALUE AFTER 5SEK. ***/
                        setTimeout(() => {
                            this.success = false;
                            this.notificationText = ""
                        }, 5000);
                    } catch(error) {
                        alert('Es gab einen Fehler beim Speichern der Daten. Bitte überprüfe deine Internetverbindung und versuche es erneut.');
                    }
                }
            },

            async remove(data) {
                if (confirm('Löschen?')) {
                    try {
                        await axios.delete('/api/teilnehmer/loeschen/' + data.id);
                        /*** REMOVE PARTICIPANT AFTER DELETE ***/
                        this.organizer.participants.splice(this.organizer.participants.indexOf(data), 1);
                        this.success = true;
                        this.notificationText = "Gelöscht!"
                        /*** FADE OUT AND TURN VALUE INTO INITIAL VALUE AFTER 5SEK. ***/
                        setTimeout(() => {
                            this.success = false;
                            this.notificationText = ""
                        }, 5000);
                    } catch(error) {
                        alert('Es gab einen Fehler beim Speichern der Daten. Bitte überprüfe deine Internetverbindung und versuche es erneut.');
                    }
                }
            },
        },

        created() {
            this.getParticipants();
        }
    };
</script>