import { createRouter, createWebHistory } from "vue-router";

import Home from '../components/Home.vue';
import OrganizerEditEvents from '../components/OrganizerEditEvents.vue';
import OrganizerEditParticipants from '../components/OrganizerEditParticipants.vue';
import OrganizerParticipations from '../components/OrganizerParticipations.vue';
import ParticipantEdit from '../components/ParticipantEdit.vue';
import ParticipantParticipations from '../components/ParticipantParticipations.vue';

const routes = [
    {
        path: '/',
        name: 'home',
        component: Home
    },

    {
        path: '/:slug_private/bearbeiten/events',
        name: 'organizer-editEvents',
        component: OrganizerEditEvents
    },

    {
        path: '/:slug_private/bearbeiten/teilnehmer',
        name: 'organizer-editParticipants',
        component: OrganizerEditParticipants
    },

    {
        path: '/:slug_private/bearbeiten',
        name: 'organizer-participations',
        component: OrganizerParticipations
    },

    {
        path: '/teilnehmer/:slug/bearbeiten',
        name: 'participant-edit',
        component: ParticipantEdit
    },

    {
        path: '/teilnehmer/:slug',
        name: 'participant-participations',
        component: ParticipantParticipations
    }
]

const router = createRouter({
    history: createWebHistory(),
    routes
})

export default router