<template>
    <!--- MENU BUTTONS TO SHOW PARTICIPANTS AND PARTICIPATIONS !--->
    <div id="participanttiles">
        <button id="participant-tile" class="tile" @click="redirectToUrl('/bearbeiten')">
            <i class="fas fa-user-edit"></i>
        </button>
        <button id="participation-tile" class="tile" @click="redirectToUrl('')">
            <i class="fas fa-users"></i>
        </button>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                slug: this.$route.params.slug
            };
        },
        
        methods: {
            redirectToUrl(url) {
                window.location.href = '/teilnehmer/' + this.slug + url;
            },
        }
    };
</script>