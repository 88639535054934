<template>
    <!--- MENU BUTTONS TO SHOW EVENTS, PARTICIPANTS AND PARTICIPATIONS !--->
    <div id="organizertiles">
        <button id="events-tile" class="tile" @click="redirectToUrl('/events')">
            <i class="far fa-calendar-alt"></i>
        </button>
        <button id="participant-tile" class="tile" @click="redirectToUrl('/teilnehmer')">
            <i class="fas fa-user-edit"></i>
        </button>
        <button id="participation-tile" class="tile" @click="redirectToUrl('')">
            <i class="fas fa-users"></i>
        </button>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                slug_private: this.$route.params.slug_private
            };
        },
        
        methods: {
            redirectToUrl(url) {
                window.location.href = '/' + this.slug_private + '/bearbeiten' + url;
            },
        }
    };
</script>