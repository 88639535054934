<!--- V-SHOW WORSE PERFORMANCE ON INITIAL-LOAD, BETTER PERFORMANCE ON TOGGLING !--->
<!--- V-IF BETTER PERFORMANCE ON INITIAL-LOAD, WORSE PERFORMANCE ON TOGGLING !--->

<template>
    <h1 v-if="organizer.name" v-text="'Übersicht Teilnehmer ' + organizer.name"></h1>
    <OrganizerMenu />
    <!--- SHOW BUTTON WHEN PASSED DATES EXIST FOR POSSIBILITY THAT USER CAN SHOW THE PASSED EVENTS !--->
    <button v-if="showPassedDates === false && passedDatesExisting === true" @click="showPassedDates = true">Vergangene Daten einblenden</button>
    <!--- SHOW BUTTON WHEN USER CLICKED TO SHOW PASSED EVENTS TO REHIDE IT AGAIN NOW !--->
    <button v-if="showPassedDates === true && passedDatesExisting === true" @click="showPassedDates = false">Vergangene Daten ausblenden</button>
    <!--- LOOP OVER ALL EVENTS !--->
    <div v-for="(event, index) in organizer.events" :key="index">
        <!--- SHOW EVENTS ONLY WHEN THEY'RE IN THE FUTURE OR WHEN THE USER WANTS TO SHOW THE PASSED EVENTS !--->
        <div v-if="checkFutureDate(event.datum) || showPassedDates === true">
            <!--- VISIBLE ACCORDION WITH EVENT-DATA !--->
            <div class="accordion" @click="toggleAccordion(index)">
                <label class="data-one-row" v-text="event.name"></label>

                <!--- OUTPUT FROM YYYY-MM-DD TO GERMAN FORMAT. NOT NECESSARY ON INPUTS !--->
                <label class="data-one-row" v-text="getDate(event.datum)"></label>
                
                <!--- JUST SHOW WHEN ONE OF THE TIMES NOT NULL !--->
                <div v-if="event.von !== null || event.bis !== null" class="data-one-row">
                    <label v-text="event.von"></label> - 
                    <label v-text="event.bis"></label>
                </div>

                <label class="data-one-row" v-text="event.ort"></label>

                <!--- NUMBER OF PARTICIPANTS FOR EVENT !--->
                <label class="data-one-row" v-text="'Teilnehmer: ' + event.participants.length"></label>

                <div class="clicks">
                    <i class="fas fa-angle-down" title="Teilnehmer anzeigen"></i>
                </div>
            </div>
            <!--- HIDDEN ACCORDION UNTIL THE USER WANTS TO SEE IT AFTER CLICK !--->
            <div class="inner-accordion" v-if="isOpen(index)">
                <ul class="data-one-row">
                    <!--- LIST ALL PARTICIPANTS OF THE EVENT !--->
                    <li v-for="participant in event.participants" v-text="participant.name"></li>
                    <!--- JUST SHOW IF THERE ARE NOT-PARTICIPANTS !--->
                    <p v-if="event.participants.length !== organizer.participants.length">Abwesend:</p>
                    <!--- LIST ALL NOT-PARTICIPANTS !--->
                    <li v-for="notParticipant in event.notParticipants" v-text="notParticipant.name"></li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
import OrganizerMenu from "../components/OrganizerMenu.vue";

export default {
    components: {
        OrganizerMenu
    },

    data() {
        return {
            organizer: JSON,
            slug_private: this.$route.params.slug_private,
            
            openAccordions: [],

            showPassedDates: false,
            passedDatesExisting: false
        };
    },

    methods: {
        async getParticipations() {
            try {
                const response = await axios.get(`/api/${this.slug_private}/bearbeiten/participations`);
                    if (response.status === 200 && response.data.organizer) {
                    this.organizer = response.data.organizer;
                }
            } catch (error) {
                console.error(error);
            }
        },

        checkFutureDate(eventDatum) {
            /*** CHECK EVENT-DATE IF IT'S NEWER THAN TODAY ***/
            if (eventDatum >= new Date().toISOString().split('T')[0]) {
                return true;
            } else {
                this.passedDatesExisting = true;
                return false;
            }
        },
            
        getDate(eventDatum) {
            return new Date(eventDatum).toLocaleString('de-ch', { weekday: 'long', year: 'numeric', month: '2-digit', day: '2-digit' });
        },

        toggleAccordion(index) {
            if (this.openAccordions.includes(index)) {
                // Accordion is already open, close it
                this.openAccordions = this.openAccordions.filter((i) => i !== index);
            } else {
                // Accordion is closed, open it
                this.openAccordions.push(index);
            }
        },

        isOpen(index) {
            return this.openAccordions.includes(index);
        },
    },

    created() {
        this.getParticipations();
    }
};
</script>