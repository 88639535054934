<!--- V-SHOW WORSE PERFORMANCE ON INITIAL-LOAD, BETTER PERFORMANCE ON TOGGLING !--->
<!--- V-IF BETTER PERFORMANCE ON INITIAL-LOAD, WORSE PERFORMANCE ON TOGGLING !--->

<template>
    <!--- SHOW ALL EVENTS OF ORGANIZER !--->
    <h1 v-text="participant.name"></h1>
    <participantMenu />
    <!--- SHOW BUTTON WHEN PASSED DATES EXIST FOR POSSIBILITY THAT USER CAN SHOW THE PASSED EVENTS !--->
    <button v-if="showPassedDates === false && passedDatesExisting === true" @click="showPassedDates = true">Vergangene Daten einblenden</button>
    <!--- SHOW BUTTON WHEN USER CLICKED TO SHOW PASSED EVENTS TO REHIDE IT AGAIN NOW !--->
    <button v-if="showPassedDates === true && passedDatesExisting === true" @click="showPassedDates = false">Vergangene Daten ausblenden</button>
    <!--- LOOP OVER ALL EVENTS !--->
    <div v-for="event in organizer.events">
        <!--- SHOW EVENTS ONLY WHEN THEY'RE IN THE FUTURE OR WHEN THE USER WANTS TO SHOW THE PASSED EVENTS !--->
        <div v-if="checkFutureDate(event.datum) || showPassedDates === true">
            <!--- VISIBLE ACCORDION WITH EVENT-DATA AND CHECK IF COLOR GREEN OR RED (PARTICIPATION) !--->
            <div class="participantEdit" :class="{'isParticipating' : checkParticipation(event.id)}">
                
                <label class="data-one-row" v-text="event.name"></label>

                <!--- OUTPUT FROM YYYY-MM-DD TO GERMAN FORMAT. NOT NECESSARY ON INPUTS !--->
                <label class="data-one-row" v-text="getDate(event.datum)"></label>

                <!--- JUST SHOW WHEN ONE OF THE TIMES NOT NULL !--->
                <div v-if="event.von !== null || event.bis !== null" class="data-one-row">
                    <label v-text="event.von"></label> - 
                    <label v-text="event.bis"></label>
                </div>

                <label class="data-one-row" v-text="event.ort"></label>

                <label class="switch clicks">
                    <input type="checkbox" :checked="checkParticipation(event.id)" @change="save(event.id)">
                    <span class="slider round"></span>
                </label>
            </div>
        </div>
    </div>

    <transition name="fade">
        <label v-if="success" class="data-one-row fade success" v-text="notificationText"></label>
    </transition>

    <transition name="fade">
        <label v-if="error" class="data-one-row fade error" v-text="notificationText"></label>
    </transition>
</template>

<script>
import ParticipantMenu from "../components/ParticipantMenu.vue";

export default {
    components: {
        ParticipantMenu
    },

    data() {
        return {
            participant: JSON,
            organizer: JSON,
            participations: JSON,
            slug: this.$route.params.slug,

            participationMap: [],

            showPassedDates: false,
            passedDatesExisting: false,
                
            /*** NOTIFICATION VALUES ***/
            success: false,
            error: false,
            notificationText: ""
        };
    },

    methods: {
        async getData() {
            try {
                const response = await axios.get(`/api/teilnehmer/${this.slug}/bearbeiten`);
                    if (response.status === 200 && response.data) {
                    this.participant = response.data.participant;
                    this.organizer = response.data.participant.organizer;
                    this.participations = response.data.participant.events;
                    this.participations.forEach(participation => {
                        this.participationMap[participation.id] = true;
                    });
                }
            } catch (error) {
                console.error(error);
            }
        },

        checkFutureDate(eventDatum) {
            /*** CHECK EVENT-DATE IF IT'S NEWER THAN TODAY ***/
            if (eventDatum >= new Date().toISOString().split('T')[0]) {
                return true;
            } else {
                this.passedDatesExisting = true;
                return false;
            }
        },

        checkParticipation(eventId) {
            /*** Check if the participationMap contains the eventId ***/
            return this.participationMap.hasOwnProperty(eventId);
        },

        toggleParticipation(eventId) {
            if (this.checkParticipation(eventId)) {
                delete this.participationMap[eventId];
            } else {
                this.participationMap[eventId] = true;
            }
        },
            
        getDate(eventDatum) {
            return new Date(eventDatum).toLocaleString('de-ch', { weekday: 'long', year: 'numeric', month: '2-digit', day: '2-digit' });
        },

        async save(eventId) {
            try {
                /*** Update the participationMap with the new eventId ***/
                if (this.checkParticipation(eventId)) {
                    delete this.participationMap[eventId];
                } else {
                    this.participationMap[eventId] = true;
                }
                await axios.post('/api/teilnahme', { eventId: eventId, participantId: this.participant.id });
                this.success = true;
                this.notificationText = "Gespeichert!"
                /*** FADE OUT AND TURN VALUE INTO INITIAL VALUE AFTER 5SEK. ***/
                setTimeout(() => {
                    this.success = false;
                    this.notificationText = ""
                }, 5000);
            } catch(error) {
                alert('Es gab einen Fehler beim Speichern der Daten. Bitte überprüfe deine Internetverbindung und versuche es erneut.');
                location.reload();
            }
        }
    },

    created() {
        this.getData();
    }
};
</script>

<style>
    /* TOGGLE */
    .switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 38px;
    }
    
    .switch input { 
    opacity: 0;
    width: 0;
    height: 0;
    }
    
    .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    -webkit-transition: .4s;
    transition: .4s;
    border: 2px solid #fff;
    }
    
    .slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
    }
    
    input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
    }
    
    /* Rounded sliders */
    .slider.round {
    border-radius: 34px;
    }
    
    .slider.round:before {
    border-radius: 50%;
    }
</style>