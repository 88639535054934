<!--- V-SHOW WORSE PERFORMANCE ON INITIAL-LOAD, BETTER PERFORMANCE ON TOGGLING !--->
<!--- V-IF BETTER PERFORMANCE ON INITIAL-LOAD, WORSE PERFORMANCE ON TOGGLING !--->

<template>
    <h1 v-if="organizer.name" v-text="'Bearbeitung ' + organizer.name"></h1>
    <OrganizerMenu />
    <!--- SHOW THE PLUS BUTTON IN ORANGE AND CREATE NEW EVENT ON CLICK !--->
    <i id="plus" class="orange fas fa-plus" @click="append()"></i>

    <!--- SHOW ALL EVENTS OF ORGANIZER WITH LOOP!--->
    <div class="event" v-for="event in organizer.events">
        <label v-if="isEditable !== event.id" class="data-one-row" v-text="event.name"></label>
        <input v-if="isEditable === event.id" class="data-one-row" type="text" v-model="event.name" required placeholder="Eventname" maxlength="40">
            
        <!--- OUTPUT FROM YYYY-MM-DD TO GERMAN FORMAT. NOT NECESSARY ON INPUTS !--->
        <label v-if="isEditable !== event.id" class="data-one-row" v-text="getDate(event.datum)"></label>
        <input v-if="isEditable === event.id" class="data-one-row" type="date" v-model="event.datum" required>
            
        <!--- JUST SHOW WHEN ONE OF THE TIMES NOT NULL. BOTH VARIANTS (LABEL AND INPUT) BECAUSE INPUTS TOO LARGE !--->
        <div v-if="isEditable !== event.id && (event.von !== null || event.bis !== null)" class="data-one-row">
            <label v-text="event.von"></label> -
            <label v-text="event.bis"></label>
        </div>
        <div v-if="isEditable === event.id" class="data-one-row">
            <input type="time" v-model="event.von"> -
            <input type="time" v-model="event.bis">
        </div>
            
        <input class="data-one-row" type="text" v-model="event.ort" :readonly="isEditable !== event.id" required placeholder="Ort" maxlength="40">

        <!--- JUST SHOW WHEN CREATING NEW EVENT !--->
        <label v-if="isEditable === event.id && edited === false" class="data-one-row">
            <input type="checkbox" id="checkbox" v-model="checked">
            Alle <b>vorhandenen</b> Teilnehmer für diesen Event anmelden
        </label>

        <!--- ICONS FOR EDIT OR DELETE !--->
        <div v-if="isEditable !== event.id" class="clicks">
            <i class="fas fa-edit" @click="edit(event)" title="Bearbeiten"></i><br><br>
            <i class="fas fa-trash" @click="remove(event)" title="Löschen"></i>
        </div>
        <div v-if="isEditable === event.id" class="clicks">
            <i class="fas fa-check-square" @click="save(event)" title="Speichern"></i><br><br>
            <i class="fas fa-ban" @click="cancel(event)" title="Abbrechen"></i>
        </div>
    </div>

    <transition name="fade">
        <label v-if="success" class="data-one-row fade success" v-text="notificationText"></label>
    </transition>

    <transition name="fade">
        <label v-if="error" class="data-one-row fade error" v-text="notificationText"></label>
    </transition>
</template>

<script>
import OrganizerMenu from "../components/OrganizerMenu.vue";

export default {
    components: {
        OrganizerMenu
    },

    data() {
        return {
            organizer: JSON,
            slug_private: this.$route.params.slug_private,

            /*** DEFAULT EDITABLE-ID ***/
            isEditable: 0,

            /*** VALUE IF EVENT WAS EDITED OR NEW CREATED FOR SAVE AND ALSO FOR REGISTERING ALL PARTICIPANTS FOR AN EVENT ON NEW EVENT ***/
            edited: false,

            checked: false,
                
            /*** NOTIFICATION VALUES ***/
            success: false,
            error: false,
            notificationText: ""
        };
    },
    
    methods: {
        async getEvents() {
            try {
                const response = await axios.get(`/api/${this.slug_private}/bearbeiten/events`);
                    if (response.status === 200 && response.data.organizer) {
                    this.organizer = response.data.organizer;
                }
            } catch (error) {
                console.error(error);
            }
        },

        getDate(eventDatum) {
            return new Date(eventDatum).toLocaleString('de-ch', { weekday: 'long', year: 'numeric', month: '2-digit', day: '2-digit' });
        },

        /*** APPEND EVENT OR PARTICIPANT ***/
        append() {
            /*** "IF 0" NEEDED TO PREVENT EDITING MULTIPLE EVENTS OR PARTICIPANTS ***/
            if (this.isEditable === 0) {
                this.isEditable = undefined;
                /*** APPEND AN EVENT-ACCORDION ***/
                this.organizer.events.unshift(this.create());
            }
        },
            
        create() {
            return {
                organizer_id: this.organizer.id,
                name: '',
                datum: '',
                von: null,
                bis: null,
                ort: '',
                /*** TO SET ALL PARTICIPANTS BE PART OF THIS EVENT BY DEFAULT ***/
                participation: false
            }
        },

        edit(data) {
            if (this.isEditable === 0) {
                /*** SAVE TEMPORARY PREVIOUS VALUES ***/
                this._tmpInputCache = Object.assign({}, data);
                /*** SET EVENT-ID AND MAKE IT EDITABLE ***/
                this.isEditable = data.id;
                this.edited = true;
            }
        },

        async remove(data) {
            if (confirm('Löschen?')) {
                try {
                    await axios.delete('/api/event/loeschen/' + data.id)
                    /*** REMOVE EVENT AFTER DELETE ***/
                    this.organizer.events.splice(this.organizer.events.indexOf(data), 1);
                    this.success = true;
                    this.notificationText = "Gelöscht!"
                    /*** FADE OUT AND TURN VALUE INTO INITIAL VALUE AFTER 5SEK. ***/
                    setTimeout(() => {
                        this.success = false;
                        this.notificationText = ""
                    }, 5000);
            } catch(error) {
                    alert('Es gab einen Fehler beim Speichern der Daten. Bitte überprüfe deine Internetverbindung und versuche es erneut.');
                }
            }
        },

        cancel(data) {
            /*** CANCEL EXISTING EVENT OR PARTICIPANT ***/
            if (this.isEditable !== undefined) {
                /*** RETURN PREVIOUS VALUES ***/
                Object.assign(data, this._tmpInputCache);
                this.edited = false;
            /*** CANCEL NEW EVENT ***/
            } else {
                this.organizer.events.splice(this.organizer.events.indexOf(data), 1);
            }
            this.isEditable = 0;
        },
            
        async save(event) {
            /*** CHECK, IF ALL PARTICIPANTS SHOULD PARTICIPATE FOR THIS EVENT ***/
            event.participation = this.checked;
            if (this.edited === false) {
                /*** CREATE NEW EVENT. WITHOUT ANSWER IT DOESN'T SAVE AND STAYS WITH INPUTS ***/
                try { await axios.post('/api/event/neu', event).then(response => {
                    event.id = response.data.id;
                    this.isEditable = 0;
                    this.organizer.events.sort((a,b) => a.datum.localeCompare(b.datum));
                    this.success = true;
                    this.notificationText = "Gespeichert!"
                    /*** FADE OUT AND TURN VALUE INTO INITIAL VALUE AFTER 5SEK. ***/
                    setTimeout(() => {
                        this.success = false;
                        this.notificationText = ""
                    }, 5000);
                });
                } catch (error) {
                    console.log(error.message);
                    /*** console.log(error.response.data.errors); ***/
                    /*** console.log(error.response); ***/
                }
            } else {
                /*** UPDATE EVENT. WITHOUT ANSWER IT DOESN'T SAVE AND STAYS WITH INPUTS ***/
                try { await axios.put('/api/event/bearbeiten', event)
                    this.isEditable = 0;
                    this.edited = false;
                    this.organizer.events.sort((a,b) => a.datum.localeCompare(b.datum));
                    this.success = true;
                    this.notificationText = "Gespeichert!"
                    /*** FADE OUT AND TURN VALUE INTO INITIAL VALUE AFTER 5SEK. ***/
                    setTimeout(() => {
                        this.success = false;
                        this.notificationText = ""
                    }, 5000);
                } catch (error) {
                    console.log(error.message);
                }
            }
        },
    },

    created() {
        this.getEvents();
    }
};
</script>